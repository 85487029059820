import * as React from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import { t } from '../js/i18n'

const pricing = {
  tiers: [
    {
      title: t('jetTitle1'),
      price: t('jetPrice1'),
      frequency: t('jetFrequency1'),
      description: t('jetDescription1'),
      features: [t('jetFeatures1'), t('jetFeatures2'), t('jetFeatures3')],
      cta: t('jetCta'),
      mostPopular: false,
    },
    {
      title: t('jetTitle2'),
      price: t('jetPrice2'),
      frequency: t('jetFrequency2'),
      description: t('jetDescription2'),
      features: [t('jetFeatures1'), t('jetFeatures2'), t('jetFeatures3')],
      cta: t('jetCta'),
      mostPopular: true,
    },
    {
      title: t('jetTitle3'),
      price: t('jetPrice3'),
      frequency: t('jetFrequency3'),
      description: t('jetDescription3'),
      features: [t('jetFeatures1'), t('jetFeatures2'), t('jetFeatures3')],
      cta: t('jetCta'),
      mostPopular: false,
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PricingJetski() {
  return (
    <div id="jetskipricing" className="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
        {t('jetTitle')}
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">{t('jetDescription')}</p>

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 py-1.5 px-4 bg-highlight-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                  Suosituin!
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">{tier.price}€</span>
                <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                {tier.features.map((feature) => {
                  if (typeof feature === 'string') {
                    return (
                      <li key={feature} className="flex">
                        <CheckIcon className="flex-shrink-0 w-6 h-6 text-highlight-300" aria-hidden="true" />
                        <span className="ml-3 text-gray-500">{feature}</span>
                      </li>
                    )
                  } else {
                    return (
                      <li key={feature.label} className="flex">
                        <CheckIcon className="flex-shrink-0 w-6 h-6 text-highlight-300" aria-hidden="true" />
                        <a href={feature.link} target="_blank" className="ml-3 text-blue-500 underline">
                          {feature.label}
                        </a>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>

            <a
              href="https://www.nettivuokraus.com/vesijetit/vuokrataan-sea-doo-170-gti-se/530099/"
              className={classNames(
                tier.mostPopular
                  ? 'bg-highlight-500 text-white hover:bg-highlight-300'
                  : 'bg-highlight-500 text-white hover:bg-highlight-300',
                'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
              )}>
              {tier.cta}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
