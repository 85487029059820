import * as React from 'react'
import Hero from './hero'
import PricingBoat from './pricingBoat'
import PricingSmallBoat from './pricingSmallBoat'
import PricingJetski from './pricingJetski'
import JetskiCalendar from './jetskiCalendar'
import Testimonials from './testimonials'
import Footer from './footer'

export default function App() {
  return (
    <>
      <Hero />
      <PricingBoat />
      <PricingJetski />
      {/*<JetskiCalendar />*/}
      <Footer />
    </>
  )
}
