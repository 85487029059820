import * as React from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import { t } from '../js/i18n'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function JetskiCalendar() {
  return (
    <div id="jetskiCalendar" className="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">{t('calendarTitle')}</h2>
      <p className="mt-6 mb-6 max-w-2xl text-xl text-gray-500">{t('calendarDescription')}</p>

      <iframe
        className="calendar"
        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FHelsinki&showTitle=0&showNav=1&showPrint=0&showCalendars=1&showTabs=1&showTz=1&mode=WEEK&hl=fi&src=YjU3ZWh2MGs1MmFyNjZlaXY5N3ZrY2h1ZDBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=N2EzbGZudDg1cDAxNThtaDBwNGUyNzQ0NG9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=NjluczhhNDJnZDhhNjdkaXJhaGhobjUwY2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=Mmo5NG1obThpczYxdWZibHFsZzBraW0ya3NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23D81B60&color=%23616161&color=%23E4C441&color=%2333B679"
        style={{ border: 'solid 1px #777' }}
        uk-responsive
        width="800"
        height="600"
        frameBorder="0"
        scrolling="no"></iframe>
    </div>
  )
}
